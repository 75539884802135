






























// Import vendors ----------------------------------------------------------------------------------
import { ref, computed, defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useFormObserver, useFormFieldText } from '@/utils/forms.utils';
// Import components -------------------------------------------------------------------------------
import AuthWrapper from '@/components/auth/AuthWrapper.vue';
import FormFieldText from '@/components/forms/FormFieldText.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthSignInChallengeSoftwareTokenMfa',
  components: { AuthWrapper, FormFieldText },
  props: {
    signInService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const rAuthWrapper = ref(null);

    const observer = useFormObserver();

    const code = useFormFieldText<string>({
      key: 'code',
      value: '',
      rules: {
        required: true,
        length: 7
      }
    });

    function submit() {
      properties.signInService.send({
        type: 'RESOLVE_CHALLENGE_SOFTWARE_TOKEN_MFA',
        data: {
          code: code.value.replace(/\s/, '')
        }
      });
    }

    const isErrored = computed(() =>
      [{ challenged: { softwareTokenMfa: 'failure' } }].some((element) =>
        properties.signInService.state.value.matches(element)
      )
    );

    const isDisabled = computed(() =>
      [{ challenged: { softwareTokenMfa: 'processing' } }, 'success'].some((element) =>
        properties.signInService.state.value.matches(element)
      )
    );

    const isLoading = computed(() =>
      [{ challenged: { softwareTokenMfa: 'processing' } }].some((element) =>
        properties.signInService.state.value.matches(element)
      )
    );

    return {
      // References
      rAuthWrapper,
      // Form observer
      observer,
      // Form fields
      code,
      // Actions
      submit,
      // Flags
      isErrored,
      isDisabled,
      isLoading
    };
  }
});
