






// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
import { useService } from '@xstate/vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocore } from '@/plugins/podocore';
// Import components -------------------------------------------------------------------------------
import AuthSignIn from '@/components/auth/signIn/AuthSignIn.vue';
// -------------------------------------------------------------------------------------------------
export default defineComponent({
  name: 'PageAuthSignIn',
  components: { AuthSignIn },
  setup() {
    const authService = useService(usePodocore().getModuleService('auth'));

    return {
      authService
    };
  }
});
