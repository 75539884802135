
















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import AuthSignInChallengeSoftwareTokenMfa from '@/components/auth/signIn/challenge/AuthSignInChallengeSoftwareTokenMfa.vue';
import AuthSignInChallengeNewPasswordRequired from '@/components/auth/signIn/challenge/AuthSignInChallengeNewPasswordRequired.vue';
import AlertError from '@/components/alerts/AlertError.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthSignInChallenge',
  components: {
    AuthSignInChallengeSoftwareTokenMfa,
    AuthSignInChallengeNewPasswordRequired,
    AlertError
  },
  props: {
    signInService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const isSoftwareTokenMfaRequested = computed(() =>
      [{ challenged: 'softwareTokenMfa' }].some((element) =>
        properties.signInService.state.value.matches(element)
      )
    );

    const isNewPasswordRequired = computed(() =>
      [{ challenged: 'newPasswordRequired' }].some((element) =>
        properties.signInService.state.value.matches(element)
      )
    );

    const unknownError = new Error('Unknown security challenge, please contact the support');

    return {
      // Flags
      isSoftwareTokenMfaRequested,
      isNewPasswordRequired,
      // Values
      unknownError
    };
  }
});
