




































// Import vendors ----------------------------------------------------------------------------------
import { ref, computed, watch, defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useFormObserver, useFormFieldText } from '@/utils/forms.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// Import components -------------------------------------------------------------------------------
import AuthWrapper from '@/components/auth/AuthWrapper.vue';
import FormFieldText from '@/components/forms/FormFieldText.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthSignInConfirm',
  components: { AuthWrapper, FormFieldText },
  props: {
    signInService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const rAuthWrapper = ref(null);

    const { trackFailure } = useAnalytics();
    const observer = useFormObserver();

    const confirmationCode = useFormFieldText<string>({
      key: 'confirmationCode',
      value: '',
      rules: {
        required: true
      }
    });

    function submit() {
      properties.signInService.send({
        type: 'CONFIRM',
        data: {
          confirmationCode: confirmationCode.value
        }
      });
    }

    const isErrored = computed(() =>
      [{ confirming: 'failure' }].some((element) => properties.signInService.state.value.matches(element))
    );
    watch(isErrored, (value) => {
      if (value)
        trackFailure('User ConfirmSignUp', properties.signInService.state.value.context.confirmingError);
    });

    const isDisabled = computed(() =>
      [{ confirming: 'processing' }, 'success'].some((element) =>
        properties.signInService.state.value.matches(element)
      )
    );

    const isLoading = computed(() =>
      [{ confirming: 'processing' }].some((element) => properties.signInService.state.value.matches(element))
    );

    return {
      // References
      rAuthWrapper,
      // Form observer
      observer,
      // Form fields
      confirmationCode,
      // Actions
      submit,
      // Flags
      isErrored,
      isDisabled,
      isLoading
    };
  }
});
