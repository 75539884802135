

































































// Import vendors ----------------------------------------------------------------------------------
import { ref, computed, defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useRoute } from '@/utils/router.utils';
import { useFormFieldText } from '@/utils/forms.utils';
// Import components -------------------------------------------------------------------------------
import AuthWrapper from '@/components/auth/AuthWrapper.vue';
import AlertSuccess from '@/components/alerts/AlertSuccess.vue';
import FormFieldText from '@/components/forms/FormFieldText.vue';
import LayoutAuthMigration from '@/layouts/auth/components/LayoutAuthMigration.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthSignInCredentials',
  components: { AuthWrapper, AlertSuccess, FormFieldText, LayoutAuthMigration },
  props: {
    signInService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const rAuthWrapper = ref(null);
    const isPasswordVisible = ref(false);

    const route = useRoute();

    const username = useFormFieldText<string>({
      key: 'username',
      type: 'email',
      value: '',
      rules: {
        required: true,
        email: true
      }
    });

    const password = useFormFieldText<string>({
      key: 'password',
      type: computed(() => (isPasswordVisible.value ? 'text' : 'password')),
      value: '',
      rules: {
        required: true
      }
    });

    function submit() {
      isPasswordVisible.value = false;
      properties.signInService.send({
        type: 'SIGN_IN',
        data: {
          username: username.value.toLowerCase(),
          password: password.value
        }
      });
    }

    const isErrored = computed(() =>
      [{ signingIn: 'failure' }].some((element) => properties.signInService.state.value.matches(element))
    );

    const isDisabled = computed(() =>
      [{ signingIn: 'processing' }, 'challenged', 'fetching', 'success'].some((element) =>
        properties.signInService.state.value.matches(element)
      )
    );

    const isLoading = computed(() =>
      [{ signingIn: 'processing' }, { signingIn: 'fetching' }].some((element) =>
        properties.signInService.state.value.matches(element)
      )
    );

    const passwordHaveBeenReset = computed(() => route.params.value.resetPassword);

    // Delete error message before unmount
    /* onBeforeUnmount(() => {
      if (isErrored.value) {
        properties.signInService.send({
          type: 'RESET',
        });
      }
    }); */

    return {
      // Values
      isPasswordVisible,
      // References
      rAuthWrapper,
      // Form fields
      username,
      password,
      // Actions
      submit,
      // Flags
      isErrored,
      isDisabled,
      isLoading,
      passwordHaveBeenReset
    };
  }
});
