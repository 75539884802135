








// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
import { useService } from '@xstate/vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocore } from '@/plugins/podocore';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  setup() {
    const authService = useService(usePodocore().getModuleService('auth'));

    const isMigrating = computed(() =>
      ['migrating'].some((element) => authService.state.value.matches(element))
    );

    return {
      isMigrating
    };
  }
});
