










// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onBeforeUnmount } from '@vue/composition-api';
import { useService } from '@xstate/vue';
// Import components -------------------------------------------------------------------------------
import AuthSignInCredentials from '@/components/auth/signIn/AuthSignInCredentials.vue';
import AuthSignInConfirm from '@/components/auth/signIn/AuthSignInConfirm.vue';
import AuthSignInCredentialsChallenge from '@/components/auth/signIn/challenge/AuthSignInChallenge.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthSignIn',
  components: { AuthSignInConfirm, AuthSignInCredentialsChallenge, AuthSignInCredentials },
  props: {
    authService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    // Extract "sign in" service from "auth" parent
    const signInService = useService(properties.authService.service.value.children.get('signIn'));

    const needConfirmation = computed(() =>
      ['confirming'].some((element) => signInService.state.value.matches(element))
    );

    const isChallenged = computed(() =>
      ['challenged'].some((element) => signInService.state.value.matches(element))
    );

    // TODO : complete
    /* const isFirstStep = computed(() =>
      ['idle'].some((element) => signInService.state.value.matches(element)),
    ); */

    // TODO : complete
    /* const subscription = signInService.service.value.subscribe((state) => {
      if (state.matches('success')) {
        root.$router.push({ name: 'auth--sign-in', params: { signIn: true } });
      }
    }); */

    // Reset service when leaving
    onBeforeUnmount(() => {
      signInService.send({
        type: 'RESET'
      });
      // subscription.unsubscribe();
    });

    return {
      // Services
      signInService,
      // Flags
      isChallenged,
      needConfirmation
      // isFirstStep,
    };
  }
});
